import React, { useEffect, useState } from 'react'

const Posts = () => {
    const [posts, setPosts] = useState();

    useEffect(() => {
        getPostsOverview();
    }, [])

    const getPostsOverview = async() => {
        const response = await fetch('/posts/_overview.json', {
            headers: {
                'content-type': 'application/json',
                'accept': 'application-json'
            }
        })

        if (response) {
            const data = await response.json();
            setPosts(data.posts);
        }
    }

    return (
        <>
            {
                posts?.map((p, i) =>  (
                    <a href={'/posts/' + p.doc} key={`post_${i}`}>
                        {p.name}
                    </a>
                ))
            }
        </>
    )
}

export default Posts