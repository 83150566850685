import React, { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import parse from 'html-react-parser'

const Post = () => {
    let { postlink } = useParams();

    const [post, setPost] = useState('loading...');
    // const [postTitle, setPostTitle] = useState('');

    const getPost = useCallback(async () => {
        const response = await fetch(`/posts/${postlink}.html`)

        if (response) {
            var data = await response.text();
            setPost(data);
        }
    },[postlink])

    useEffect(() => {
        getPost();
    }, [getPost])

    return (
        <div className=''>
            {parse(post)}
        </div>
    )
}

export default Post