import './App.css';
import { Routes, Route } from 'react-router-dom'

import Layout from './components/Layout'
import Home from './components/Home'
import Missing from './components/Missing'
import Posts from './components/Posts'
import Post from './components/Post'
import Portfolio from './components/Portfolio';

function App() {
  return (
    <Routes>
      <Route  path='/' element={<Layout/>}>
        <Route path='/' element={<Home/>}/>
        <Route path='/posts' element={<Posts/>}/>
        <Route path='/portfolio' element={<Portfolio/>}/>
        <Route path='/posts/:postlink' element={<Post/>}/>
        <Route path="*" element={<Missing />}/>
      </Route>
    </Routes>
  );
}

export default App;
