import React from 'react'

const Nav = () => {
  return (
    <nav className='h-28 bg-gradient-to-r from-indigo-500 to-green-500'>
      <ul className='flex pt-8 justify-center text-white font-semibold'>
        <li className='p-2 hover:underline cursor-pointer'>
          <a href='/'>Home</a>
        </li>
        <li className='p-2 hover:underline cursor-pointer'>
          <a href='/posts'>Posts</a>
        </li>
        <li className='p-2 hover:underline cursor-pointer'>
          <a href='/portfolio'>Portfolio</a>
        </li>
      </ul>
    </nav>
  )
}

export default Nav