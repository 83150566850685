import React from 'react'
import { Outlet } from 'react-router-dom'
import Nav from './Nav'

const Layout = () => {
  return (
    <main className='bg-stone-50 h-screen'>
        <Nav/>
        <div className='container mx-auto'>
            <Outlet/>
        </div>
    </main>
  )
}

export default Layout