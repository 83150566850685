import React from 'react'

const Home = () => {
  return (
    <div className='mx-auto w-fit'>
        <p className='text-lg pt-4'>
          This is mostly just a hub site for accessing personal projects. If you find yourself here without being given a link, why?
        </p>
    </div>
  )
}

export default Home