import React, { useState, useEffect } from 'react'

const Portfolio = () => {
    const [projects, setProjects] = useState();

    useEffect(() => {
        getProjectsOverview();
    }, [])

    const getProjectsOverview = async () => {
        const response = await fetch('/portfolio/_overview.json', {
            headers: {
                'content-type': 'application/json',
                'accept': 'application-json'
            }
        })

        if (response) {
            const data = await response.json();
            setProjects(data.projects);
        }
    }

    return (
        <div className='w-fit mx-auto'>
            <div className='flex flex-wrap'>
                {
                    projects?.map((p, i) => (
                        <a className='w-1/3' href={p.link}>
                            <div key={`proj_${i}`} className='m-1 hover:invert cursor-pointer bg-zinc-600 p-3 rounded-xl'>
                                <h1 className='text-center text-white text-xl'>{p.title}</h1>
                                <p className='text-center text-white font-light truncate ... h-6' title={p.desc}>{p.desc}</p>
                                <img alt={p.title} src={'/portfolio/' + p.image} className='w-fit'></img>
                            </div>
                        </a>

                    ))
                }
            </div>
        </div>
    )
}

export default Portfolio